import http from "./api";
import { logout } from "./localStorage";

function createChunks(file: File, chunkSize: number) {
  const chunks = [];
  let offset = 0;

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + chunkSize);
    chunks.push(chunk);
    offset += chunkSize;
  }

  return chunks;
}

function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


export const verifySizeUser = async (file: File,) => {
  return await http.get(`/bff/upload-bff/limit-upload-user`, {
    params: {
      size: file.size / (1024 * 1024),
    }
  }).then(response => response.data)
    .catch(err => {
      if (err.response.status === 401) {
        logout()
        window.location.reload()
      }
      throw err;
    });
}

export const verifySizeZone = async (file: File, idZone: number) => {
  return await http.get(`/bff/upload-bff/limit-upload-zone`, {
    params: {
      size: file.size / (1024 * 1024),
      zoneId: idZone
    }
  }).then(response => response.data)
    .catch(err => {
      if (err.response.status === 401) {
        logout()
        window.location.reload()
      }
      throw err;
    });
}


export const uploadArchive = async (
  file: File,
  url: string,
  body: any,
  type: "POST" | "PUT"
) => {
  if (!file) {
    alert("Please select a file first");
    return;
  }

  const chunkSize = 0.9 * 1024 * 1024; // 0.9MB
  const chunks = createChunks(file, chunkSize);
  const uuid = generateUUID();
  const originalName = file.name;
  const mimetype = file.type;
  const totalChunks = chunks.length;
  for (let i = 0; i < totalChunks; i++) {
    const chunk = chunks[i];
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("uuid", uuid);
    formData.append("index", i.toString());
    formData.append("originalName", originalName);
    formData.append("mimetype", mimetype);


    await http
      .post("/bff/upload-bff", formData)
      .then((data) => { })
      .catch((err: any) => {
        alert(err);
      });
  }

  await finalizeUpload(uuid, originalName, mimetype, url, body, type);
};

async function finalizeUpload(
  uuid: string,
  originalName: string,
  mimetype: string,
  url: string,
  body: any,
  type: "POST" | "PUT"
) {
  try {

    const bodycomplete = { uuid: uuid, originalName: originalName, mimetype: mimetype };

    await http
      .post("/bff/upload-bff/complete", bodycomplete)
      .then((data) => { })
      .catch((err) => { });

    await compressFile(uuid, originalName, mimetype);


    if (type === "POST") {
      await http.post(url, {
        ...body,
        uuid: uuid,
        originalName: originalName,
        mimetype: mimetype,
      });
    }

    if (type === "PUT") {
      await http.put(url, {
        ...body,
        uuid: uuid,
        originalName: originalName,
        mimetype: mimetype,
      });
    }


  } catch (error) {
    console.error("Error finalizing upload:", error);
  }
}

async function compressFile(
  uuid: string,
  originalName: string,
  mimetype: string
) {
  try {
    const body = { uuid: uuid, originalName: originalName, mimetype: mimetype };

    http
      .post("/bff/upload-bff/compress", body)
      .then((data) => { })
      .catch((err) => {
        alert("Error compress upload:" + err);
      });
  } catch (error) {
    console.error("Error compressing file:", error);
  }
}
