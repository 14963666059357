// import { ZonaContext } from "../../../ludz.one_sdk/controllers/Zona/ControllerRoom/context";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SessionZonaContext } from "../../../../contexts/Zone/SessionZone/context";
import { SessionZonaContextType } from "../../../../contexts/Zone/SessionZone/type";
import { ZonaContext } from "../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../contexts/Zone/Zone/type";
import { Accordion, Button, Progress } from "../../../../ludz.one_ui";
import LudzScrollTop from "../../../../ludz.one_ui/LudzScrollTop";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { Row, RowReverse } from "../../../../styles/globalStyles";
import { Container } from "../../../style";
import BodySession from "./BodySession";
import Content from "./Content/content";
import CreateSession from "./CreateSession";
import HeaderAccordion from "./HeaderAccordion";
import { useMediaQuery } from "@mui/material";
import { useFetchZoneSessionsWatchedBff } from "../../../../ludzoneSDK/Zone/Session/WatchedSessionContents/query";
import queryClient from "../../../../services/react-query";
import { Toast } from "primereact/toast";

const TimelineScreen = () => {
  const [visibleCreateSection, setVisibleCreateSection] = useState(false);

  const componentRef = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 360 });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
      }
    });

    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(componentRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const { session, DeleteSession, allContentSession, handleWatched, toast, toastwatched } =
    useContext(SessionZonaContext) as SessionZonaContextType;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      queryClient.removeQueries("UseZoneSessionWatchedBff");
      setLoading(false);
    }
  }, [loading]);

  const { adminorowner } = useContext(ZonaContext) as ZonaContextType;
  const { idsessioncontent, id } = useParams();
  const history = useNavigate();
  const { data, refetch } = useFetchZoneSessionsWatchedBff(
    allContentSession && !idsessioncontent
      ? allContentSession![0]?.id
      : parseInt(idsessioncontent!)
  );

  const matches = useMediaQuery("(max-width: 360px)");

  function encontrarIndices(array: any, elemento: number) {
    var indices = 0;
    for (let i = 0; i < array?.length; i++) {
      if (array![i].id === elemento) {
        indices = i;
      }
    }
    return indices;
  }

  const indice = encontrarIndices(
    allContentSession,
    parseInt(idsessioncontent!)
  );
  const NextContent = () => {
    if (idsessioncontent) {
      if (!data.session_content_fk) {
        handleWatched(idsessioncontent!);
      }
      if (!(indice === allContentSession?.length! - 1)) {
        history(`/zona/${id}/timeline/${allContentSession![indice! + 1].id}`);
        refetch();
      }
    }
    if (allContentSession![1] && !idsessioncontent) {
      if (!data.session_content_fk) {
        handleWatched(allContentSession![0].id.toString());
      }
      if (!(indice === allContentSession?.length! - 1)) {
        history(`/zona/${id}/timeline/${allContentSession![1].id}`);
        refetch();
      }
    }

    // window.location.reload();
  };
  const BackContent = () => {
    if (idsessioncontent) {
      const indice = encontrarIndices(
        allContentSession,
        parseInt(idsessioncontent)
      );
      history(`/zona/${id}/timeline/${allContentSession![indice! - 1].id}`);
    }
    if (allContentSession![1] && !idsessioncontent) {
      history(`/zona/${id}/timeline/${allContentSession![1].id}`);
    }
    refetch();
    // window.location.reload();
  };

  return (
    <Container>
      <Toast ref={toast} />
      <Toast ref={toastwatched}/>
      {/* {adminorowner ? <IsAdmin/> : <IsUser/>} */}
      <h2>Conteúdo</h2>
      <Padding padding="16px" />
      {adminorowner ? (
        <Button
          title="Criar Seção"
          onClick={() => setVisibleCreateSection(!visibleCreateSection)}
        />
      ) : null}
      <Padding padding="8px" />
      {session?.length !== 0 ? (
        <div
          className="card w-full h-auto"
          style={{ padding: matches ? "10px" : "20px" }}
        >
          <RowReverse>
            <div className="w-full lg:w-8 overflow-y-auto">
              {session ? (
                <div ref={componentRef} style={{ minHeight: 360 }}>
                  {session[0]?.contents[0]?.id ? (
                    <Content />
                  ) : (
                    <h4>Sem Conteúdo</h4>
                  )}
                </div>
              ) : null}
            </div>
            <div
              style={{ overflowY: "scroll", maxHeight: size.height }}
              className="lg:w-4 w-full"
            >
              {session ? (
                <Accordion
                  activeIndex
                  body={BodySession}
                  header={HeaderAccordion}
                  data={session}
                  onDelete={DeleteSession}
                />
              ) : (
                <Progress />
              )}
            </div>
          </RowReverse>
          <Padding padding="6px" />
          <Row id="center">
            <Button
              title={matches ? "" : "Voltar"}
              icon="pi pi-arrow-left"
              pt={{ icon: { style: { marginRight: "8px" } } }}
              disabled={indice === 0}
              onClick={BackContent}
            />
            {allContentSession ? (
              <Button
                title={
                  matches
                    ? ""
                    : indice === allContentSession?.length - 1
                    ? "Finalizar"
                    : "Próximo"
                }
                icon={
                  indice === allContentSession?.length - 1
                    ? "pi pi-check"
                    : "pi pi-arrow-right"
                }
                iconPos="right"
                onClick={NextContent}
              />
            ) : null}
          </Row>
        </div>
      ) : (
        <h4>Sem Conteúdo</h4>
      )}

      <CreateSession
        visible={visibleCreateSection}
        onOpen={() => setVisibleCreateSection(!visibleCreateSection)}
      />
      <LudzScrollTop style={{ padding: "15px", right: "0%" }} />
    </Container>
  );
};

export default TimelineScreen;
