import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { Toast } from "primereact/toast";
import { useContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { KanbanSubzonaContext } from "../../../../contexts/Subzone/KanbanSubzone/context";
import { KanbanSubzonaContextType } from "../../../../contexts/Subzone/KanbanSubzone/type";
import {
  Card,
  PropsSubzoneKanbanBFF,
} from "../../../../ludzoneSDK/Subzone/Kanban/type";
import AddList from "../AddList";
import { Id } from "../types";
import ColumnContainer from "./ColumnContainer";
import TaskCard from "./TaskCard";


interface Props {
  datafilter: any
}

function KanbanBoard({ datafilter }: Props) {
  const { setkanban, moveMouserCard, handleUpdateKanban, handleDeleteKanban, moveMouserColumn, toastKanbanList, laziLoading } = useContext(
    KanbanSubzonaContext
  ) as KanbanSubzonaContextType;

  const [tasks, setTasks] = useState<Card[]>([]);

  // Tamanho do lote
  const batchSize = 4;
  // useEffect(() => {
  //   if (datafilter.length > 0 && loading) {
  //     const arrayCards = [];
  //     for (const kanbanOne of datafilter) {
  //       for (const card of kanbanOne.kanbanCards?.slice(0, batchSize * laziLoading.find(props => props.idKanban === kanbanOne.id)?.currentBatch!)) {

  //         arrayCards.push(card);
  //       }
  //     }
  //     setTasks(arrayCards);
  //     setLoading(false)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [datafilter]);

  useEffect(() => {
    const arrayCards: any = [];



    if (laziLoading[0]?.idKanban) {
      console.log(arrayCards)
      for (const kanbanOne of datafilter) {
        for (const card of kanbanOne.kanbanCards?.slice(0, batchSize * laziLoading.find(props => props.idKanban === kanbanOne.id)?.currentBatch!)) {
          if (card.kanban_fk === laziLoading.find(props => props.idKanban === kanbanOne.id)?.idKanban && !tasks.some((task) => task.id === card.id)) {
            arrayCards.push(card);
          }
        }
      }
      setTasks((prevTasks) => [...prevTasks, ...arrayCards]);
    } 
    if(arrayCards.length === 0) {
      const arrayCards = [];
      for (const kanbanOne of datafilter) {
        for (const card of kanbanOne.kanbanCards?.slice(0, batchSize * laziLoading.find(props => props.idKanban === kanbanOne.id)?.currentBatch!)) {
          arrayCards.push(card);
        }
      }
      setTasks(arrayCards);
    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laziLoading, datafilter]);

  // useEffect(() => {
  //   // Função para carregar o próximo lote de itens
  //   const loadMoreItems = () => {
  //     setCurrentBatch((prevBatch) => prevBatch + 1);
  //   };

  //   // Configura o Intersection Observer
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         loadMoreItems();
  //       }
  //     },
  //     { threshold: 1 }
  //   );

  //   // Observa o elemento "sentinela" que disparará o carregamento
  //   if (observerRef.current) {
  //     observer.observe(observerRef.current);
  //   }

  //   // Cleanup do observador ao desmontar
  //   return () => {
  //     if (observerRef.current) observer.unobserve(observerRef?.current);
  //   };
  // }, [observerRef]);

  const columnsId = useMemo(() => datafilter?.map((col: any) => col.id), [datafilter]);

  const [activeColumn, setActiveColumn] =
    useState<PropsSubzoneKanbanBFF | null>(null);

  const [activeTask, setActiveTask] = useState<Card | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );


  return (
    <>
      {datafilter ? (
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <Toast ref={toastKanbanList} />
          <div className=" flex gap-4">
            <div className="flex gap-4">
              <SortableContext items={columnsId!}>
                {datafilter?.map((col: any) => (
                  <div style={{ maxHeight: "90vh" }}>
                    <ColumnContainer
                      key={col.id}
                      column={col}
                      deleteColumn={deleteColumn}
                      updateColumn={updateColumn}
                      deleteTask={deleteTask}
                      updateTask={updateTask}
                      tasks={tasks.filter(
                        (props) => props.kanban_fk === col.id
                      )}
                    />


                  </div>
                ))}
              </SortableContext>
            </div>
            <div style={{ height: "fitContent" }}>
              <AddList />
            </div>
          </div>

          {createPortal(
            <DragOverlay>
              {activeColumn && (
                <ColumnContainer
                  column={activeColumn}
                  deleteColumn={deleteColumn}
                  updateColumn={updateColumn}
                  deleteTask={deleteTask}
                  updateTask={updateTask}
                  tasks={tasks.filter((task) => task.id === activeColumn.id)}
                />
              )}
              {activeTask && (
                <TaskCard
                  task={activeTask}
                  deleteTask={deleteTask}
                  updateTask={updateTask}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      ) : null}
    </>
  );

  function deleteTask(id: Id) {
    const newTasks = tasks.filter((task) => task.id !== id);
    setTasks(newTasks);
  }

  function updateTask(id: Id, content: string) {
    const newTasks = tasks.map((task) => {
      if (task.id !== id) return task;
      return { ...task, content };
    });

    setTasks(newTasks);
  }

  function deleteColumn(id: number) {
    const filteredColumns = datafilter?.filter((col: any) => col.id !== id);
    setkanban(filteredColumns);

    const newTasks = tasks.filter((t) => t.kanban_fk !== id);
    setTasks(newTasks);
    handleDeleteKanban(id)

  }

  function updateColumn(id: Id, title: string) {
    const newColumns = datafilter?.map((col: any) => {
      if (col.id !== id) return col;
      return { ...col, title };
    });

    setkanban(newColumns);
    handleUpdateKanban({ title: title }, +id)
  }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Column") {
      setActiveColumn(event.active.data.current.column);
      return;
    }

    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  }

  function onDragEnd(event: DragEndEvent) {
    setActiveColumn(null);
    setActiveTask(null);


    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveAColumn = active.data.current?.type === "Column";
    if (!isActiveAColumn) return;


    setkanban((columns: any) => {
      const activeColumnIndex = columns.findIndex(
        (col: any) => col.id === activeId
      );

      const overColumnIndex = columns.findIndex(
        (col: any) => col.id === overId
      );
      moveMouserColumn({ kanbanId: parseInt(activeId.toString()), newIndex: overColumnIndex })
      return arrayMove(columns, activeColumnIndex, overColumnIndex);
    });
  }

  function onDragOver(event: DragOverEvent) {


    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";

    if (!isActiveATask) return;

    if (isActiveATask && isOverATask) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        const overIndex = tasks.findIndex((t) => t.id === overId);
        const columnFind = datafilter?.find(
          (props: any) => props.id === tasks[overIndex].kanban_fk
        );
        const cardOver = columnFind?.kanbanCards.findIndex(
          (props: any) => tasks[overIndex].id === props.id
        );

        if (tasks[activeIndex].kanban_fk !== tasks[overIndex].kanban_fk) {
          // Fix introduced after video recording
          tasks[activeIndex].kanban_fk = tasks[overIndex].kanban_fk;
          moveMouserCard({
            cardId: tasks[activeIndex].id,
            newIndex: cardOver,
            newKanbanId: columnFind?.id,
          });
          return arrayMove(tasks, activeIndex, overIndex - 1);
        }

        moveMouserCard({
          cardId: tasks[activeIndex].id,
          newIndex: cardOver,
          newKanbanId: columnFind?.id,
        });
        return arrayMove(tasks, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);

        tasks[activeIndex].kanban_fk = overId;


        moveMouserCard({
          cardId: tasks[activeIndex].id,
          newIndex: 0,
          newKanbanId: parseInt(tasks[activeIndex].kanban_fk.toString()),
        });
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  }
}


export default KanbanBoard;
