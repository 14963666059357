import styled from "styled-components";
import styles from "../ludz.one_ui/styles";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px 64px;
  background-color: #F2F4F6;
  grid-area: "children";
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 2560px) and (max-width: 3480px) {
    padding: 4%;
  }

  @media (max-width: 920px) {
    padding: 20px;
  }

  @media (max-width: 520px) {
    padding: 10px;
    h1{
      font-size: x-large;
    }
  }

  @media (max-width: 360px) {
    h1{
      font-size: large;
    }
  }
  `;

export const Containergeral = styled.div`
   width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: rgba(235, 235, 235, 0.8); /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${styles.colors.primary}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }

  @media (max-width: 820px) {
    padding-bottom: 30px;
  }

  @media (max-width: 520px) {

    h2{
      font-size: 20px;
    }
  }

  @media (max-width: 330px) {
    padding-bottom: 5px;
  }

`;