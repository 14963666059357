import { useMutation } from "react-query";
import { useFetchZoneBff, useFetchZoneLimitSizeBff } from "./query";
import { CreateZoneRequest, PutLastAcess } from "./request";
import { PropsCreateZone } from "./type";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../components/Alert";

export const CreateZoneController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    const history = useNavigate()

    const CreateZoneMutation = useMutation((data: PropsCreateZone) => CreateZoneRequest(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
        Alert({ summary: "Erro", detail: `Aconteceu algum problema ${error.response.data.message === 'You have reached the maximum number of zones allowed' && ', Você atingiu o limite máximo de criação de zona!'}`, severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any, variables, context) => {
            console.log(result)
            history("/home")
            Swal.fire({
               
                icon: "success",
                title: "Zona criada!",
                showConfirmButton: false,
                timer: 2000
              });
        },
      });

    return{
        CreateZoneMutation,
    }
}



export const ZoneBffController = (id: string | undefined) => {

    const { data, refetch } = useFetchZoneBff(id)

    const LastAcess = useMutation((data: {zoneId: number}) => PutLastAcess(data), {
        onMutate: () => {
        //   setLoading(true); 
        //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);

            console.log(error)
        },
        onSuccess: (result, variables, context) => {
        },
      });

    return{
        data, refetch, LastAcess
    }
}

export const ZoneLimitSizeBffController = (id: string | undefined) => {

  const { data, refetch } = useFetchZoneLimitSizeBff(id)

  const LastAcesssLimitSize = useMutation((data: {zoneId: number}) => PutLastAcess(data), {
    onMutate: () => {
    //   setLoading(true); 
    //   queryClient.refetchQueries()
    },
    onError: (error: any) => {
    //   setError(error.response.data.message);
    //   setLoading(false);

        console.log(error)
    },
    onSuccess: (result, variables, context) => {
    },
  });

  return{
      data, refetch, LastAcesssLimitSize
  }
}