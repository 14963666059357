import React, { useContext } from "react";
import {
  TabView,
  TabPanel,
  TabPanelHeaderTemplateOptions,
} from "primereact/tabview";
import PrivateRoutesProvider, { PrivateRoutesContext } from "../../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../../contexts/PrivateRoutes/types";
import { UserProfileContext } from "../../../../contexts/User/Profile/context";
import { UserProfileContextType } from "../../../../contexts/User/Profile/type";
import HeaderProfile from "../../../../components/HeaderProfile";
import ProfileInfoProvider from "../../../../contexts/User/Profile/ProfileVisible/context";
import ProfileInfo from "../ProfileInfo";
import ProfileInvites from "../ProfileInvites";
import ProfileConfig from "../ProfileConfig";
import EditPasswordProvider from "../../../../contexts/User/Profile/ProfileEditPassword/context";
import ProfileNotifications from "../ProfileNofications";

export default function LudzTabView() {
  const { activeIndex, setActiveIndex } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;


  const { userprofile, limitsize } = useContext(
    UserProfileContext
  ) as UserProfileContextType;

  const tab1HeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div onClick={options.onClick} className={options.className}>
        Perfil
        <i className="pi pi-user-edit ml-2" />
      </div>
    );
  };

  const tab2HeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div onClick={options.onClick} className={options.className}>
        Notificações
        <i className="pi pi-bell ml-2" />
      </div>
    );
  };

  const tab3HeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div onClick={options.onClick} className={options.className}>
        Convites
        <i className="pi pi-user-plus ml-2" />
      </div>
    );
  };

  const tab4HeaderTemplate = (options: TabPanelHeaderTemplateOptions) => {
    return (
      <div onClick={options.onClick} className={options.className}>
        Configurações
        <i className="pi pi-cog ml-2" />
      </div>
    );
  };

  return (
    <ProfileInfoProvider>
      <HeaderProfile userprofile={userprofile}  limitsize={limitsize} />
      <TabView
        style={{ transition: "all 0.3s linear", marginTop: "60px" }}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel  headerTemplate={tab1HeaderTemplate}>
          <ProfileInfo />
        </TabPanel>
        <TabPanel  headerTemplate={tab2HeaderTemplate}>
          <PrivateRoutesProvider>
          <ProfileNotifications userprofile={userprofile} />
          </PrivateRoutesProvider>
        </TabPanel>
        <TabPanel headerTemplate={tab3HeaderTemplate}>
          <ProfileInvites userprofile={userprofile} />
        </TabPanel>
        <TabPanel headerTemplate={tab4HeaderTemplate}>
          <EditPasswordProvider>
            <ProfileConfig userprofile={userprofile} />
          </EditPasswordProvider>
        </TabPanel>
      </TabView>
    </ProfileInfoProvider>
  );
}
