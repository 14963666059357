import { useContext } from "react";
import { ProfileContext } from "../../contexts/User/Profile/ProfileVisible/context";
import { ProfileContextType } from "../../contexts/User/Profile/ProfileVisible/type";
import { Tooltip } from "../../ludz.one_ui";
import EditAvatar from "./EditAvatar";
import * as C from "./style";
import { PrivateRoutesContext } from "../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../contexts/PrivateRoutes/types";

interface Props {
  userprofile: any;
  limitsize?: string;
}


const formatSize = (size: number) => {
  if (size >= 1024) {
    return `${(size / 1024).toFixed(2)} GB`;
  } else if (size < 1) {
    return `${(size * 1024).toFixed(2)} KB`;
  }
  return `${size.toFixed(2)} MB`;
};

const HeaderProfile = ({ userprofile, limitsize }: Props) => {
  const { HandleSaveAvatar, HandleRemoveAvatar, isediting, setIsEditing } =
    useContext(ProfileContext) as ProfileContextType;

  const { userProvider } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const remove = () => {
    HandleRemoveAvatar();
  };

  const formattedLimitSize = limitsize ? formatSize(parseFloat(limitsize)) : "0 MB";

  return (
    <C.Container>
      <C.AreaInfo>
        <C.EditAvatar
          onMouseMove={() => setIsEditing(true)}
          onMouseLeave={() => setIsEditing(false)}
        >
          <EditAvatar
            remove={remove}
            isediting={isediting}
            setIsEditing={setIsEditing}
            initialAvatarUrl={userprofile?.aws?.url}
            HandleSaveAvatar={HandleSaveAvatar}
          />
          {isediting ? (
            <Tooltip title="Remover" placement="bottom" arrow>
              <i
                onClick={remove}
                className="pi pi-trash"
                style={{
                  marginTop: "5px",
                  color: "orange",
                  fontSize: "1rem",
                }}
              ></i>
            </Tooltip>
          ) : null}
        </C.EditAvatar>
        <C.AreaDataUser>
          <h1>{userprofile?.name}</h1>
          <span>
            @{userprofile?.username} • Plano: {userProvider?.plan.name}
            {userProvider?.plan.artificialIntelligence && " • Com IA "} • Armazenamento utilizado: {formattedLimitSize}
          </span>
        </C.AreaDataUser>
      </C.AreaInfo>
      <C.UserDatas>
        <h4>Zonas: {userprofile?._count?.zoneUser}</h4>
        <h4>Subzonas: {userprofile?._count?.subZoneUsers}</h4>
        <h4>Canais: {userprofile?._count?.channels}</h4>
      </C.UserDatas>
    </C.Container>
  );
};

export default HeaderProfile;
