import { useMutation } from "react-query";
import { DeleteSubzoneArchivesRequest, SubzoneArchivesRequest } from "./request";
import queryClient from "../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../components/Alert";

export const SubzoneArchivesController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {


    const CreateSubZoneArchivesMutation = useMutation(({data, id}: {data: File, id: string}) => SubzoneArchivesRequest(data, id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        },
        onSuccess: (result, variables, context) => {
            Alert({
                detail: "Arquivo enviado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneBff")

        },
    });

    const DeleteSubZoneArchivesMutation = useMutation((id: string) => DeleteSubzoneArchivesRequest(id), {
        onMutate: () => {
            //   setLoading(true);
            //   queryClient.refetchQueries()
        },
        onError: (error: any) => {
            //   setError(error.response.data.message);
            //   setLoading(false);
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        },
        onSuccess: (result, variables, context) => {
            Alert({
                detail: "Arquivo excluido!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseSubzoneBff")
        },
    });


    return {
        CreateSubZoneArchivesMutation, DeleteSubZoneArchivesMutation
    }
}