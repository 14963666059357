import { Tooltip, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, Icon } from "../../../../ludz.one_ui";
import LudzLoading from "../../../../ludz.one_ui/Loading";
import LudzScrollTop from "../../../../ludz.one_ui/LudzScrollTop";
import styles from "../../../../ludz.one_ui/styles";
import { Padding } from "../../../../ludz.one_ui/styles/styled-components";
import { useFetchChannelVideosYoutube } from "../../../../ludzoneSDK/Channel/query";
import { formatDate } from "../../../../services/functionGlobal";
import { Column, Row, RowReverse } from "../../../../styles/globalStyles";
import { Container } from "../../../style";
import { AreaInfo, CapaChannel } from "../../BuyChannel/style";
import { ContentCard } from "../Contents/style";
import { ChannelListVideosYoutube, Item2 } from "./type";
import capa from "../../../../assets/images/zone_wallpaper_6.jpeg"

const YoutubeContentState = (id: string) => {
    const [channelVideos, setChannelVideos] = useState<ChannelListVideosYoutube | undefined>()
    const { data: videosReques, isLoading } = useFetchChannelVideosYoutube(id)
    const [videoState, setVideo] = useState<Item2 | undefined>()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const idVideo = queryParams.get('video');

    useEffect(() => {
        if (videosReques) {
            setChannelVideos(videosReques)
            const video = channelVideos?.jsonDataDetails.items.find(props => props.id.videoId === idVideo)
            if (video) {
                setVideo(video)
            }
        }


    }, [videosReques, idVideo, channelVideos?.jsonDataDetails.items])

    return { channelVideos, videoState, isLoading }
}

const YoutubeContent = ({ idYoutube }: { idYoutube: string }) => {
    const { id } = useParams()

    const matchesSmall = useMediaQuery("(min-width: 800px)");
    const matchesExtraSmall = useMediaQuery("(min-width: 600px)");
    const matchesMobile = useMediaQuery("(max-width: 520px)");
    const matchesTablet = useMediaQuery("(max-width: 1024px)");
    const props = YoutubeContentState(idYoutube)

    const history = useNavigate()

    if (props.isLoading) return <LudzLoading />

    const NameRoomFormat = (name: any) => {
        if (name?.length > 30) {
            return name?.substring(0, 30) + "...";
        }
        return name;
    };

    function encontrarIndices(array: Item2[], elemento: string) {
        var indices = 0;
        for (let i = 0; i < array?.length; i++) {
            if (array![i].id.videoId === elemento.toString()) {
                indices = i;
            }
        }
        return indices;
    }

    const indice = encontrarIndices(props.channelVideos?.jsonDataDetails.items!, props.videoState?.id.videoId! ?? props.channelVideos?.jsonDataDetails.items[0].id.videoId);
    const NextContent = () => {
        if (id) {
            history(`/canal/${id}?video=${props.channelVideos?.jsonDataDetails.items![indice! + 1].id.videoId}`);
        }
        if (props.channelVideos?.jsonDataDetails.items![1] && !id) {
            history(`/canal/${id}?video=${props.channelVideos?.jsonDataDetails.items![1].id.videoId}`);
        }
        window.location.reload();
    };
    const BackContent = () => {
        if (id) {
            history(`/canal/${id}?video=${props.channelVideos?.jsonDataDetails.items![indice! - 1].id.videoId}`);
        }
        if (props.channelVideos?.jsonDataDetails.items![1] && !id) {
            history(`/canal/${id}?video=${props.channelVideos?.jsonDataDetails.items![1].id.videoId}`);
        }
        window.location.reload();
    };


    return (
        <Container>
            {props.isLoading ? <LudzLoading /> : <Column style={{ border: "1px solid #c3c3c3", borderRadius: "24px",  backgroundColor: "rgba(127, 100, 170, 0.09)" }}>
                <CapaChannel
                    background={props.channelVideos?.jsonData?.items[0]?.brandingSettings?.image?.bannerExternalUrl! ?? capa}
                ></CapaChannel>
                <Padding />
                <Avatar
                    shape="circle"
                    size={matchesMobile ? "large" : "xlarge"}
                    label={props.channelVideos?.jsonData?.items[0]?.snippet?.thumbnails?.default?.url ? null : "L"}
                    img={
                        props.channelVideos?.jsonData?.items[0]?.snippet?.thumbnails?.medium.url
                    }
                    style={{
                        border: "1px solid #CCC",
                        marginLeft: "20px",
                        marginTop: matchesMobile ? '-50px' : "-70px",
                        width: matchesMobile
                            ? "80px"
                            : "120px",
                        height: matchesMobile
                            ? "80px"
                            : "120px",
                    }}
                />
                <Column
                    id="space-between"
                    className="mx-3 mt-2"

                >
                    <Row style={{ display: "flex", alignItems: "center", marginLeft: '10px' }}>
                        <Column>
                            <Tooltip title={props?.channelVideos?.jsonData?.items[0]?.snippet?.title}>
                                <h1
                                    className="edit-in-place"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            NameRoomFormat(props?.channelVideos?.jsonData?.items[0]?.snippet?.title) ||
                                            "Escreva Alguma coisa",
                                    }}
                                ></h1>
                            </Tooltip>
                            <AreaInfo><span>{props.channelVideos?.jsonDataDetails.items.length} vídeos</span>
                            </AreaInfo>
                        </Column>
                    </Row>
                    <Padding padding="8px" />
                    <div className="card mb-3">
                        <h4 className="card-title mb-3">Descrição</h4>
                        <p
                            className="edit-in-place"
                            dangerouslySetInnerHTML={{
                                __html:
                                    props?.channelVideos?.jsonData?.items[0].snippet.description ||
                                    "Escreva Alguma coisa",
                            }}
                        />
                    </div>

                    {/* {props.playlistChannel?.tags.length > 0 && (
          <div className="card mb-0">
            <h4 className="card-title mb-3">Tags</h4>
            <div className="flex justify-center items-center flex-wrap gap-2">
              {props.playlistChannel?.tags?.map(
                (item: any, index: number) => (
                  <Tag
                    className="p-2"
                    rounded
                    style={{ background: "#E5E6EA", color: "black" }}
                  >
                    <span className="text-base">{item.name}</span>
                  </Tag>
                )
              )}
            </div>
          </div>
        )} */}
                </Column>
                <Padding padding="8px" />
                <Row id="center">
                    {props?.channelVideos ? (
                        <div className="card w-full mx-3 mb-3" >
                            {props.channelVideos.jsonDataDetails.items.length !== 0 ? (
                                <div style={{ overflowY: "auto" }}>
                                    <h4 className="mb-3">Vídeos</h4>
                                    <h2>{props.videoState?.snippet.title ?? props.channelVideos?.jsonDataDetails.items[0].snippet.title}</h2>
                                    <RowReverse style={{ flexDirection: matchesTablet ? 'column' : 'row' }}>
                                        <div className="w-full md:w-12">
                                            <Padding padding="8px" />
                                            <Row>
                                                <ReactPlayer
                                                    url={props.videoState ? 'https://www.youtube.com/watch?v=' + props.videoState?.id.videoId : 'https://www.youtube.com/watch?v=' + props.channelVideos?.jsonDataDetails.items[0].id.videoId}
                                                    width={"100%"}
                                                    height={
                                                        matchesSmall
                                                            ? 420
                                                            : matchesExtraSmall
                                                                ? 380
                                                                : 260
                                                    }
                                                    controls
                                                />
                                            </Row>
                                            <Padding />
                                            <Padding padding="8px" />
                                            <h4>Descrição</h4>
                                            <Padding padding="8px" />
                                            <p>{props.videoState?.snippet.description ?? props?.channelVideos.jsonDataDetails.items[0].snippet.description}</p>
                                            <Padding padding="8px" />
                                            <Row>
                                                <Icon icon="pi pi-clock" size="0.8rem" />
                                                <h6>
                                                    {formatDate(props.videoState?.snippet.publishedAt ?? props?.channelVideos.jsonDataDetails.items[0].snippet.publishedAt!)}
                                                </h6>
                                            </Row>
                                        </div>
                                        <div className="md:w-12 w-full" style={{ overflowY: "auto", height: 1028 }}>
                                            <Padding padding="8px" />
                                            <div
                                                style={{
                                                    borderLeft: `0.5px solid ${styles.colors.grayClearOne}`,
                                                    height: "100%",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                {props?.channelVideos.jsonDataDetails.items?.map(
                                                    (item, index) => {
                                                        return (
                                                            <ContentCard key={index} onClick={() => { history(`/canal/${id}?video=${item.id.videoId}`); }}>
                                                                <Padding padding="8px">
                                                                    <Row>
                                                                        {/* <Column id="center">
                                                                            <Checkbox checked />
                                                                        </Column> */}
                                                                        <Row id="center" >

                                                                            <img style={{ borderRadius: "8px" }} src={!matchesSmall ? item.snippet.thumbnails.default.url : item.snippet.thumbnails.default.url} alt="" />
                                                                        </Row>
                                                                        <Padding />
                                                                        <Column>
                                                                            <h5>{item.snippet.title}</h5>
                                                                            <Padding />
                                                                            <p>
                                                                                {item.snippet.description.length > 128 ? item.snippet.description.substring(0, 64) + "..." : item.snippet.description}
                                                                            </p>
                                                                        </Column>
                                                                    </Row>
                                                                </Padding>
                                                            </ContentCard>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </RowReverse>
                                    <Padding padding="16px" />
                                    <Row id="center">
                                        <Button
                                            title="Voltar"
                                            icon="pi pi-arrow-left"
                                            pt={{ icon: { style: { marginRight: "8px" } } }}
                                            disabled={indice === 0}
                                            onClick={BackContent}
                                        />
                                        {props?.channelVideos.jsonDataDetails.items ? (
                                            <Button
                                                title="Próximo"
                                                icon="pi pi-arrow-right"
                                                iconPos="right"
                                                disabled={
                                                    indice === props?.channelVideos.jsonDataDetails.items?.length - 1
                                                }
                                                onClick={NextContent}
                                            />
                                        ) : null}
                                    </Row>
                                </div>
                            ) : (
                                <h1>Sem conteúdo</h1>
                            )}
                        </div>
                    ) : (
                        <LudzLoading />
                    )}
                </Row>
            </Column>}
            <LudzScrollTop style={{ right: "0.5%", padding: "15px" }} />
        </Container>
    )

    // return (
    //     <Container>
    //         <h3>{props.videoState?.snippet.title ?? props.channelVideos?.jsonDataDetails.items[0].snippet.channelTitle}</h3>
    //         <Padding padding="16px" />
    //         <RowReverse >
    //             <ReactPlayer
    //                 url={props.videoState ? 'https://www.youtube.com/watch?v=' + props.videoState?.id.videoId : 'https://www.youtube.com/watch?v=' + props.channelVideos?.jsonDataDetails.items[0].id.videoId}
    //                 width={matchesSmall ? "60%" : "100%"}
    //                 height={
    //                     matchesSmall ? 500 : matchesExtraSmall ? 380 : 260
    //                 }
    //                 controls
    //             />
    //             <Padding />
    //             <Column>
    //                 <h3>{props.videoState?.snippet.title ?? props.channelVideos?.jsonDataDetails.items[0].snippet.title}</h3>
    //                 <Padding padding="16px" />
    //                 <h4>Descrição</h4>
    //                 <Padding />
    //                 <p>
    //                     {props.videoState?.snippet.description ?? props.channelVideos?.jsonDataDetails.items[0].snippet.description}
    //                 </p>
    //             </Column>

    //         </RowReverse>
    //         <Padding padding="32px" />
    //         <div className="grid">

    //             {props.channelVideos?.jsonDataDetails.items.map((item) => {
    //                 return (
    //                     <div className="col-12 md:col-6 lg:col-4 xl:col-3" onClick={() => { history("?video=" + item.id.videoId); }}>
    //                         <CardVideo item={item} />
    //                     </div>
    //                 )
    //             })}
    //         </div>

    //     </Container>
    // )
}

export default YoutubeContent