import { createContext } from "react";
import { SessionZoneState } from "./state";
import { SessionZonaContextType } from "./type";

export const SessionZonaContext = createContext<SessionZonaContextType | null>(
  null
);

interface Props {
  children: React.ReactNode;
}

const SessionZonaProvider = ({ children }: Props) => {
  const {
    deleteSessionContentWatched,
    handleWatched,
    handleSession,
    initialValues,
    session,
    initialValuesContent,
    handleSessionContentsFile,
    DeleteSession,
    schema,
    deleteSessionContent,
    handleEditSessionContent,
    handleEditSession,
    accordionDisa,
    setAccordionDisa,
    allContentSession,
    toast,
    toastwatched,
  } = SessionZoneState();

  return (
    <SessionZonaContext.Provider
      value={{
        handleSession,
        initialValues,
        session,
        initialValuesContent,
        handleSessionContentsFile,
        DeleteSession,
        schema,
        deleteSessionContent,
        handleEditSessionContent,
        handleEditSession,
        accordionDisa,
        setAccordionDisa,
        allContentSession,
        deleteSessionContentWatched,
        handleWatched,
        toast,
        toastwatched,
      }}
    >
      {children}
    </SessionZonaContext.Provider>
  );
};

export default SessionZonaProvider;
