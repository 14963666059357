import http from "../../services/api"
import { logout } from "../../services/localStorage"
import { PropsCreateZone } from "./type"

export const CreateZoneRequest = async (body: PropsCreateZone) => {
     return await http.post("/direct/zone", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               console.log(err)
               throw err;
          });
}

export const PutLastAcess = async (body: {zoneId: number}) => {
     return await http.put("/bff/aux-bff/update-lastAccess", body).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               alert(err.response.data.message)
               console.log(err)
               throw err;
          });
}

export const GetOneZoneRequest = async (id: string | undefined, query: string) => {
     const params = encodeURIComponent(`{"subZones": {"include": {"user_owner": true, "archives": true}}, "zoneMembers": {"include": {"user": true}}}`);

     return await http.get(`/direct/zone/${id}?include=${params}`).then((response) => {
          
      
     })
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}


export const GetZoneBffRequest = async (id: string | undefined) => {
     
     return await http.get(`/bff/zone-bff`, {
          params: {
               zoneId: id,
          }
      }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}

export const GetZoneLimitSizeBffRequest = async (id: string | undefined) => {
     
     return await http.get(`/bff/upload-bff/limit-size-upload-zone`, {
          params: {
               zoneId: id,
          }
      }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}



