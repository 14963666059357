import http from "../../services/api"
import { logout } from "../../services/localStorage"




export const GetUserBffRequest = async (id: string | undefined) => {
     
     return await http.get(`/bff/users-bff/home-user`, {
          params: {
               id: id,
          }
      }).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}

export const GetUserLimitSizeBffRequest = async () => {
     
     return await http.get(`/bff/upload-bff/limit-size-upload-user`).then(response => response.data)
          .catch(err => {
               if (err.response.status === 401) {
                    logout()
                    window.location.reload()
               }
               throw err;
          });
}