import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserBffController, UserLimitSizeBffController } from "../../../ludzoneSDK/Profile/controller";
import { UserType } from "../../../ludzoneSDK/Profile/types";


export const UserProfileState = () => {

    const { id } = useParams();
    const [userprofile, setUserProfile] = useState<UserType>();
    const [limitsize, setLimitSize] = useState<string>()
    const [activeIndex, setActiveIndex] = useState(0);


    const { data, refetch } = UserBffController(id);

    

    const datalimitsize = UserLimitSizeBffController()

    useEffect(() => {
        if (data) {
            setUserProfile({
                id: data.id,
                name: data.name,
                username: data.username,
                password: data.password,
                email: data.email,
                verified: data.verified,
                aws: data.aws,
                notifications: data.notifications,
                invites: data.invites,
                _count: data._count
            })
            console.log(data)
        }
        if (datalimitsize) {
            setLimitSize(datalimitsize.data)
            console.log(limitsize)
        }
    }, [data, datalimitsize, limitsize])

    return {
        userprofile, refetch, activeIndex, setActiveIndex, limitsize
    }
}