// import { Container } from './styles';
import { useLocation, useNavigate } from "react-router-dom";
import background from "../../../assets/images/background.jpg";
import { ChannelBusiness } from "../../../contexts/PrivateRoutes/types";
import { Avatar, Tooltip } from "../../../ludz.one_ui";
import { Container } from "./style";
import { useMediaQuery } from "@mui/material";

const CardChannel = ({
  channel,
  contentQnt,
  playlistsQnt,
}: {
  channel: ChannelBusiness;
  contentQnt?: number;
  playlistsQnt?: number;
}) => {
  const history = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery("(min-width: 2000px)");
  const matchesMobile = useMediaQuery("(max-width: 920px)")

  //   const DecimalBR = () => {
  //     let numero = 0;

  //     return parseFloat(numero.toFixed(2)).toLocaleString("pt-BR", {
  //       minimumFractionDigits: 2,
  //     });
  //   };

  const NameRoomFormat = (name: string) => {
    if (name?.length > 30) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  const Descriptionchannel = (description: string) => {
    if (description?.length > 40) {
      return description.substring(0, 40) + "...";
    } else {
      return description;
    }
  };

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const formatDate = () => {
    if (channel?.createdAt || channel?.createdAt) {
      const date = new Date(channel?.createdAt ?? channel?.createdAt);

      var day: any = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      var postDate = day + " " + months[date.getMonth()].substring(0, 3);
      return postDate;
    } else {
      return "Não possui data";
    }
  };
  const description = Descriptionchannel(
    channel?.description ?? channel?.description
  );
  const strippedText = description?.replace(/<[^>]*>/g, "");

  return (
    <div className="p-1 md:p-2">
      <Container
        className="w-full border-1 surface-border cursor-pointer z-index"
        style={{ borderRadius: "10px" }}
        onClick={() => {
          if (location.pathname === "/home") {
            history(`/canal/${channel?.id}`);
          }
          if (location.pathname === "/home/canaispublicos") {
            history(`/compracanal/${channel?.id}`);
          }
        }}
      >
        <div className="relative">
          <img
            src={channel?.aws_2?.url ? channel?.aws_2?.url : channel?.youtubeData?.jsonData![0]?.brandingSettings?.image?.bannerExternalUrl  ? channel?.youtubeData?.jsonData[0].brandingSettings.image.bannerExternalUrl : background}
            className="w-full"
            alt={channel?.name}
            style={{
              borderRadius: "10px",
              height: matchesMobile ? '160px' : '180px',
              maxHeight: matches ? 250 : 200,
              borderBottom: "1px solid #d6d6d6",
            }}
          />
          <Avatar
            label={channel?.aws?.url ? null : channel?.name?.substring(0, 1)}
            img={channel?.aws?.url ? channel?.aws?.url : channel?.youtubeData?.jsonData![0]?.snippet?.thumbnails?.medium?.url  ? channel?.youtubeData?.jsonData![0]?.snippet.thumbnails.medium.url : null}
            className="flex absolute border-circle border-1 border-gray-300"
            style={{ bottom: "-1.5rem", left: "1.2rem" }}
            shape="circle"
            size="xlarge"
          />
        </div>
        <div className="p-3 mt-3">
          <div className="mb-3">
            <h4 className="text-900 font-semibold text-md md:text-xl">
              {NameRoomFormat(channel?.name)}
            </h4>
          </div>

          <p
            className="text-700 text-sm md:text-lg mt-0 mb-5"
            dangerouslySetInnerHTML={{
              __html: Descriptionchannel(strippedText),
            }}
            style={{ wordBreak: "break-all" }}
          />
          {channel ? (
            <div
              className="icon-container flex flex-wrap gap-2 align-items-center justify-content-between"
              style={{ bottom: "16px" }}
            >
              <span className="flex align-items-center text-900 text-sm md:text-lg">
                <>
                  <i className="pi pi-video mr-2"></i>
                  <Tooltip title="Subchannels">
                    <span className="font-semibold">
                      {contentQnt ?? channel?.contentQnt}
                    </span>
                  </Tooltip>
                </>
              </span>
              <span className="flex align-items-center text-900 text-sm md:text-lg">
                <i className="pi pi-list mr-2"></i>
                <Tooltip title="Membros">
                  <span className="font-semibold">
                    {playlistsQnt ?? channel?.playlistsQnt}
                  </span>
                </Tooltip>
              </span>
              <span className="flex align-items-center text-900 text-sm md:text-lg">
                <i className="pi pi-clock mr-2"></i>
                <Tooltip title="Data de criação">
                  <span className="font-semibold mr-1">{formatDate()}</span>
                </Tooltip>
              </span>
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default CardChannel;
