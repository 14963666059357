import { useQuery } from "react-query";
import { GetOneZoneRequest, GetZoneBffRequest, GetZoneLimitSizeBffRequest } from "./request";

export const useFetchZoneOne = (id: string | undefined, query: string) => {
    return useQuery(["UseZoneId", id], () => GetOneZoneRequest(id, query));
};

export const useFetchZoneBff = (id: string | undefined) => {
    return useQuery(["UseZoneIdBff", id], () => GetZoneBffRequest(id));
};

export const useFetchZoneLimitSizeBff = (id: string | undefined) => {
    return useQuery(["UseZoneLimitSizeBff", id], () => GetZoneLimitSizeBffRequest(id));
};
